.footer_wrapper {
    background: #0B2931;
    display: inline-flex;
    padding: 35px 28px 0px 28px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;

}

.social_media_icons_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 350px;

}

.social_media_icon {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: flex-start;
    flex-shrink: 0;
}

.contact_us_button {
    display: flex;
    width: 100%;
    padding: 7px;
    justify-content: center;
    align-items: canter;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #898D9A;
    background-color: inherit;
    color: #898D9A;
    max-width: 350px;
    margin-top: 20px;
}

.mail_icon {
    max-width: 25px;
    max-height: 25px;
}

.copy_rights_text_wrapper {
    color: #898D9A;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    /* 100% */
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cookies_settings {
    margin: 15px auto;
}

.icons_mail_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

}

@media screen and (min-width: 666px) {
    .icons_mail_wrapper {
        flex-direction: row;
        justify-content: space-around;
    }

    .social_media_icons_wrapper {
        width: 40%;
    }

    .contact_us_button {
        margin-top: 0;
        width: 40%;
    }

}