.bundle_details_wrapper {
    display: flex;
    width: 100%;
    padding: 20px 5%;
}

.bundle_details_sec_wrapper {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;

}

.img_sec_wrapper {
    width: 50%;
    min-height: 60vh;
    border-radius: 25px;
    position: relative;
}

.gradient_text {
    display: flex;
    font-size: 30px;
    font-weight: 500;
    background: -webkit-linear-gradient(#a8c41e, #037283, #037283);
    background: -o-linear-gradient(#a8c41e, #037283, #037283);
    background: -moz-linear-gradient(#a8c41e, #037283, #037283);
    background: linear-gradient(to right, #a8c41e, #037283, #037283);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.secondary_text {
    font-size: 14px;
    color: #c3c3c3;
    width: 100%;
    /* margin-top: 10px; */
    padding: 10px 40% 10px 0;
}

.payment_button {
    padding: 10px 15px;
    border: 10px;
    background-image: linear-gradient(to right, #a8c41e, #037283);
    border-radius: 10px;
    color: #fff;
    margin-top: 28px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymob {
    font-family: 'Tektur', cursive;
    font-size: 20px;
}

.ikarus_logo {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 70px;
}

.download_apk_sec {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.card_item_wrapper {
    display: flex;
    width: 100%;
    font-size: 12px;
    flex-wrap: wrap;
}

.button {
    background-color: black;
    display: flex;
    border-radius: 5px;
    color: white;
    align-items: center;
    border: 1px solid white;
    margin: 8px 8px 8px 0;
    padding: 0 8px;
}

.button_text_content {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 8px;
}

.google_play_logo {
    width: 20px;
}

.buttons_wrapper {
    display: flex;
}

.package_info_wrapper {
    font-size: 14px
}
.bundel_details_download_app_buttons {
    display: none;
}
@media screen and (max-width: 1024px) {
    .secondary_text {
        padding: 10px 20% 10px 0;
    }

    .payment_button {
        width: 70%;
    }
}

@media screen and (max-width: 760px) {
    .img_sec_wrapper {
        display: none;
    }

    .bundle_details_sec_wrapper {
        width: 100% !important;
    }

    .bundel_details_download_app_buttons {
        display: block;
    }
    .payment_button {
       width: 280px;
        margin-bottom: 10px;
    }
}

